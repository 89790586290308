import React from 'react';
import RecipeDetails from '../components/RecipeDetails';

function DrinkRecipe() {
  return (
    <RecipeDetails />
  );
}

export default DrinkRecipe;
