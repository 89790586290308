import React from 'react';
// import { useHistory } from 'react-router-dom';
import ProgressRecipe from '../components/ProgressRecipe';

function RecipeInProgress() {
  return (
    <ProgressRecipe />
  );
}

export default RecipeInProgress;
