import React from 'react';
import RecipeDetails from '../components/RecipeDetails';

function MealRecipe() {
  return (
    <RecipeDetails />
  );
}

export default MealRecipe;
